import React, { useState } from "react";
import "./Navbar.css";
import Logo from "./IMG/LOGO.svg";
import { Link } from "react-router-dom";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = () => {
    setIsOpen(false);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  return (
    <>
      <header className="header">
        <div className="top-bar">
          <div className="top-bar__content">
            <section className="phone">
              <i className="fa-solid fa-phone icon"></i>
              {/* <span className="top-details">1-800-922-0444</span> */}
              <a href="tel:+61 469 207 452" className="top-details">
                +61 469 207 452
              </a>
            </section>
            <section className="email">
              <i className="fa-solid fa-envelope icon"></i>
              {/* <span className="top-details">info@cloudservices.com</span> */}
              <a
                href="mailto:Gleamingfloorscleaning@gmail.com"
                className="top-details"
              >
                Gleamingfloorscleaning@gmail.com
              </a>
            </section>
          </div>
        </div>

        <div className="bottom-bar">
          <div className="bottom-bar__content">
            <a href="#" className="logo">
              <img className="logo__img" src={Logo} alt="logo" />
            </a>

            <nav className={`nav ${isOpen ? "nav--open" : ""}`}>
              <ul className="nav__list">
                <li className="nav__item">
                  <Link to="/" className="nav__link" onClick={closeNavbar}>
                    Home
                  </Link>
                </li>
                <li className="nav__item">
                  <Link to="/about" className="nav__link" onClick={closeNavbar}>
                    About Us
                  </Link>
                </li>
                <li
                  className="nav__item"
                  onMouseEnter={() => setIsDropdownOpen(true)}
                  onMouseLeave={() => setIsDropdownOpen(false)}
                >
                  <Link
                    className="nav__link"
                    onClick={(e) => {
                      if (
                        e.target.textContent === "Residential" ||
                        e.target.textContent === "Commercial"
                      ) {
                        e.preventDefault();
                      }
                    }}
                  >
                    Services
                  </Link>
                  {isDropdownOpen && (
                    <div className="dropdown-menu">
                      <ul className="dropdown-menu__list">
                        <li className="dropdown-menu__item">
                          <Link
                            className="Stef"
                            to="/CarpetCleaning"
                            onClick={closeNavbar}
                          >
                            Carpet Cleaning
                          </Link>
                        </li>
                        <li className="dropdown-menu__item">
                          <Link
                            className="Stef"
                            to="/VacateCleaning"
                            onClick={closeNavbar}
                          >
                            Vacate Cleaning
                          </Link>
                        </li>
                        <li className="dropdown-menu__item">
                          <Link
                            className="Stef"
                            to="/WindowCleaning"
                            onClick={closeNavbar}
                          >
                            Window Cleaning
                          </Link>
                        </li>
                        <li className="dropdown-menu__item">
                          <Link
                            className="Stef"
                            to="/Office"
                            onClick={closeNavbar}
                          >
                            Office & Commercial Cleaning
                          </Link>
                        </li>
                        <li className="dropdown-menu__item">
                          <Link
                            className="Stef"
                            to="/Grout"
                            onClick={closeNavbar}
                          >
                            Grout Cleaning
                          </Link>
                        </li>
                        <li className="dropdown-menu__item">
                          <Link
                            className="Stef"
                            to="/Pressurecleaning"
                            onClick={closeNavbar}
                          >
                            Pressure Cleaning
                          </Link>
                        </li>
                        <li className="dropdown-menu__item">
                          <Link
                            className="Stef"
                            to="/Upholstery"
                            onClick={closeNavbar}
                          >
                            Upholstery Cleaning
                          </Link>
                        </li>
                        <li className="dropdown-menu__item">
                          <Link
                            className="Stef"
                            to="/Rugs"
                            onClick={closeNavbar}
                          >
                            Rugs Cleaning
                          </Link>
                        </li>
                        <li className="dropdown-menu__item">
                          <Link
                            className="Stef"
                            to="/OfficeCarpet"
                            onClick={closeNavbar}
                          >
                            Office Carpet Cleaning
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )}
                  <ul className="dropdown-menu__list_Responsive">
                    <li className="dropdown-menu__item">
                      <Link to="/Residential" onClick={closeNavbar}>
                        Carpet Cleaning
                      </Link>
                    </li>
                    <li className="dropdown-menu__item">
                      <Link to="/Commercial" onClick={closeNavbar}>
                        Vacate Cleaning
                      </Link>
                    </li>
                    <li className="dropdown-menu__item">
                      <Link to="/Commercial" onClick={closeNavbar}>
                        Window Cleaning
                      </Link>
                    </li>
                    <li className="dropdown-menu__item">
                      <Link to="/Commercial" onClick={closeNavbar}>
                        Office & Commercial Cleaning
                      </Link>
                    </li>
                    <li className="dropdown-menu__item">
                      <Link to="/Commercial" onClick={closeNavbar}>
                        Grout Cleaning
                      </Link>
                    </li>
                    <li className="dropdown-menu__item">
                      <Link to="/Commercial" onClick={closeNavbar}>
                        Car Carpet Seats Cleaning
                      </Link>
                    </li>
                    <li className="dropdown-menu__item">
                      <Link to="/Commercial" onClick={closeNavbar}>
                        Upholstery Cleaning
                      </Link>
                    </li>
                    <li className="dropdown-menu__item">
                      <Link to="/Commercial" onClick={closeNavbar}>
                        Rugs Cleaning
                      </Link>
                    </li>
                    <li className="dropdown-menu__item">
                      <Link to="/Commercial" onClick={closeNavbar}>
                        Office Carpet Cleaning
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav__item">
                  <Link to="/Blog" className="nav__link" onClick={closeNavbar}>
                    Blogs
                  </Link>
                </li>
                <li className="nav__item">
                  <Link
                    to="/Contact"
                    className="nav__link"
                    onClick={closeNavbar}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </nav>

            <div
              className={`hamburger ${isOpen ? "hamburger--open" : ""}`}
              onClick={toggleNavbar}
            >
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Navbar;
