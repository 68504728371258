import React, { useEffect, useState } from "react";
import "./Contact.css";
import { Icon } from "@iconify/react";
import phoneCallingBold from "@iconify/icons-solar/phone-calling-bold";
import roundEmail from "@iconify/icons-ic/round-email";
import locationFilled from "@iconify/icons-carbon/location-filled";
import Footer from "../Footer/Footer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Contact() {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    service: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare the data to send to the API
    const data = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      phone_number: formData.phone,
      services: formData.service,
      message: formData.message,
    };

    try {
      const response = await fetch(
        "https://gleamingfloors.com.au/admin/db/api/contact.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        // Form submitted successfully
        console.log("Form submitted successfully");
        toast.success("Form submitted successfully"); // Display success toast
      } else {
        // Handle form submission error here
        console.error("Form submission failed");
        toast.error("Form submission failed"); // Display error toast
      }
    } catch (error) {
      // Handle network errors
      console.error("An error occurred while submitting the form:", error);
      toast.error("An error occurred while submitting the form"); // Display error toast
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <ToastContainer />

      <div className="Dammy"></div>
      <h1 className="Contact-hedding">Contact Us</h1>
      <p className="C-description">Contact us today for a spotless space!</p>
      <div className="Contect-Details">
        <div className="Details">
          <h2 className="Detail-con">Contact Information</h2>
          <p className="Sey-fetr">Say something to start a live chat!</p>
          <div className="Conection-data">
            <p className="Set-number">
              <Icon className="iCon" icon={phoneCallingBold} />
              <a className="Conctign">+61 469 207 452</a>
            </p>
            <p className="Set-number">
              <Icon className="iCon" icon={roundEmail} />
              <a className="Conctign">Gleamingfloorscleaning@gmail.com</a>
            </p>
            <p className="Set-number">
              <Icon className="iCon" icon={locationFilled} />
              <a className="Conctign">
                212, French Street, Tuart Hill, Perth, Western Australia
              </a>
            </p>
          </div>
        </div>
        <div className="Form-Fillling">
          <div className="Fill-data">
            <form className="From" onSubmit={handleSubmit}>
              <div className="input-fildw">
                <p className="fild-tezt">First Name</p>
                <input
                  className="Input-wch"
                  type="text"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="input-fildw">
                <p className="fild-tezt">Last Name</p>
                <input
                  className="Input-wch" 
                  type="text"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="input-fildw">
                <p className="fild-tezt">Email</p>
                <input
                  className="Input-wch"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="input-fildw">
                <p className="fild-tezt">Phone Number</p>
                <input
                  className="Input-wch"
                  type="number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="input-fildw1">
                <p className="fild-tezt">Select Services</p>
                <select
                  className="Input-wch"
                  name="service"
                  value={formData.service}
                  onChange={handleInputChange}
                >
                  <option value=""></option>
                  <option value="Carpet Cleaning">CARPET CLEANING</option>
                  <option value="Vacate Cleaning">VACATE CLEANING</option>
                  <option value="Window Cleaning">WINDOW CLEANING</option>
                  <option value="Office & Commercial Cleaning">
                    OFFICE & COMMERCIAL CLEANING
                  </option>
                  <option value="Grout Cleaning">GROUT CLEANING</option>
                  <option value="Car Carpet Seats Cleaning">
                    CAR CARPET SEATS CLEANING
                  </option>
                  <option value="Upholstery Cleaning">
                    UPHOLSTERY CLEANING
                  </option>
                  <option value="Rugs Cleaning">RUGS CLEANING</option>
                  <option value="Office Carpet Cleaning">
                    OFFICE CARPET CLEANING
                  </option>
                </select>
              </div>
              <div className="input-fildw1">
                <p className="fild-tezt">Message</p>
                <input
                  className="Input-wch"
                  type="text"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                />
              </div>
              <button className="Btn-Ban" type="submit">
                book appointment
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
