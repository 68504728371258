import React, { useEffect } from "react";
import "./Services.css";
import { Icon } from "@iconify/react";
import check2Line from "@iconify/icons-mingcute/check-2-line";
import RSMain from "./images/Services/Grout-1.jpg";
import Ser9 from "./images/img9.png";
import Ser10 from "./images/img10.png";
import Ser11 from "./images/img11.png";
import Ser12 from "./images/img12.png";
import Ser13 from "./images/img13.png";
import Ser14 from "./images/img14.png";
import Ban9 from "./images/Group4.png";
import Footer from "../Footer/Footer";
import Newserc from "./images/Services/Grout-2.jpg";

function Grout() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="Dammy"></div>
      <div className="Banner-all">
        <h2 className="Ba-ab-set">Grout Cleaning</h2>
      </div>
      <div className="Text-img-ser">
        <div className="SText-contaner">
          <div className="Blocl">
            <h1 className="setup-text">Revive Grout Beauty</h1>
            <p className="Stp098">
              Revive Grout Beauty is a service that specializes in restoring the
              visual appeal of grout lines. Grout, the material used to fill
              gaps between tiles, is prone to staining and discoloration over
              time. This service involves thorough cleaning and treatment
              techniques.
              <br />
              It can make a significant difference in the overall appearance of
              your tile installations, enhancing the aesthetics of your space.
            </p>
          </div>
          <div className="set-checks">
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Grout Renewal
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Refresh
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Reveal the Beauty
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Deserve a Makeover
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Elevate Your Space
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Revive Grout Beauty
            </p>
          </div>
        </div>
        <div className="SImg-con">
          <img className="imgs-set" src={RSMain} alt="" />
        </div>
      </div>
      <div className="Ser-section-aed">
        <div className="Ser-bsu">
          <img className="Set-fjimg" src={Newserc} alt="" />
        </div>
        <div className="Ser-bsu">
          <h2 className="setupn-text">The Ultimate Grout Cleaning Services</h2>
          <p className="Stp098">
            Our experienced team clean that removes deep-seated dirt and stains.
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
              We utilize advanced cleaning techniques and specialized equipment.{" "}
            </p>
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
              Our professionals are skilled at removing even the toughest
              stains, whether from spilled liquids, mold, mildew, or other
              sources of discoloration.
            </p>
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
              We address and eliminate mold and mildew growth, ensuring a
              healthier and cleaner environment.{" "}
            </p>
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
              We prioritize the use of environmentally friendly cleaning
              products and practices to reduce our impact on the environment
            </p>
          </p>
        </div>
      </div>
      <div className="Frendly">
        <div className="Set-Secet">
          <h1 className="TExt-secret">
            Fast, Friendly, and Satisfaction Guarantee
          </h1>
          <p className="Min-texsecre">
            Experience fast and friendly carpet cleaning with our satisfaction
            guarantee. We promise a fresh, clean carpet that meets your highest
            expectations.
          </p>
        </div>
        <div className="Stser982">
          <div className="Set-1-ser">
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser9} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Satisfaction Guarantee</h2>
                <p className="set-Lodw3">
                  Our cleaning service comes with a satisfaction guarantee: if
                  you're not happy, we'll make it right.
                </p>
              </div>
            </div>
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser10} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Local Professionals</h2>
                <p className="set-Lodw3">
                  Trust your home to local cleaning professionals who know your
                  area best. Experience quality service from your community
                  experts.
                </p>
              </div>
            </div>
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser11} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Flexible Appointments</h2>
                <p className="set-Lodw3">
                  Enjoy the convenience of flexible appointments with our
                  cleaning service, tailored to your schedule and needs.
                </p>
              </div>
            </div>
          </div>
          <div className="Set-1-ser">
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser12} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Free Quotes</h2>
                <p className="set-Lodw3">
                  Request your free cleaning service quote today, and discover
                  our competitive rates to keep your space spotless!
                </p>
              </div>
            </div>
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser13} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Fast 24-Hour Service</h2>
                <p className="set-Lodw3">
                  Experience our lightning-fast 24-hour cleaning service, ready
                  to tackle any cleaning challenge around the clock
                </p>
              </div>
            </div>
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser14} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">100% Commitment-Free</h2>
                <p className="set-Lodw3">
                  Our cleaning service offers a 100% commitment-free
                  consultation, so you can explore your options with no
                  obligations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ST-baneuy875">
        <div className="Ban98-img">
          <img className="HDf9826" src={Ban9} alt="" />
        </div>
        <div className="Ban98-img">
          <h1 className="set-text09">How Home Pro works?</h1>
          <div className="Line-one">
            <p className="Linumber">1.</p>
            <p className="LiHedcont">Grout Cleaning</p>
            <p className="LINContent">
              The team meticulously cleans the grout lines, utilizing
              specialized equipment to remove dirt.
            </p>
          </div>
          <div className="Line-one">
            <p className="Linumber">2.</p>
            <p className="LiHedcont">Sealing (Optional)</p>
            <p className="LINContent">
              Upon request, HomePro can apply a grout sealer to protect your
              freshly cleaned grout lines.
            </p>
          </div>
          <div className="Line-one">
            <p className="Linumber">3.</p>
            <p className="LiHedcont">Friendly Practices</p>
            <p className="LINContent">
              Gleaming prioritizes the use of eco-friendly cleaning products.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Grout;
