import React from "react";
import "./Footer.css";
import Logo from "./IMG/Logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Icon } from "@iconify/react";
import locationFilled from "@iconify/icons-carbon/location-filled";
import callIcon from "@iconify/icons-tdesign/call";
import mailIcon from "@iconify/icons-tabler/mail";
import sharpFacebook from "@iconify/icons-ic/sharp-facebook";
import instagramIcon from "@iconify/icons-mdi/instagram";
import twitterOutline from "@iconify/icons-basil/twitter-outline";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  const Carpetcleaning = () => {
    navigate("/CarpetCleaning");
  };
  const VacateCleaning = () => {
    navigate("/VacateCleaning");
  };
  const WindowCleaning = () => {
    navigate("/WindowCleaning");
  };
  const officecommercial = () => {
    navigate("/Office");
  };
  const Groupcleaning = () => {
    navigate("/Grout");
  };
  const Carcarpet = () => {
    navigate("/Pressurecleaning");
  };
  const Upholsterycleaning = () => {
    navigate("/Upholstery");
  };
  const Rugscleaning = () => {
    navigate("/Rugs");
  };
  const Officecleaning = () => {
    navigate("/OfficeCarpet");
  };

  const Home = () => {
    navigate("/");
  };
  const About = () => {
    navigate("/about");
  };
  const Blogs = () => {
    navigate("/Blog");
  };
  const ContactUs = () => {
    navigate("/Contact");
  };
  return (
    <>
      <footer class="footer-section">
        <div class="container">
          <div class="footer-cta pt-5 pb-5">
            <div class="row">
              <div class="col-xl-4 col-md-4 mb-30">
                <div class="single-cta">
                  {/* <i class="fas fa-map-marker-alt"></i> */}
                  <Icon className="Icon-Footer" icon={locationFilled} />
                  <div class="cta-text">
                    <h4>Find us</h4>
                    <a className="Contact-detial">
                      212, French Street, Tuart Hill, Perth, Western Australia  
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-md-4 mb-30">
                <div class="single-cta">
                  {/* <i class="fas fa-phone"></i> */}
                  <Icon className="Icon-Footer" icon={callIcon} />
                  <div class="cta-text">
                    <h4>Call us</h4>
                    <a href="tel:+61 469 207 452" className="Contact-detial">
                      +61 469 207 452
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-md-4 mb-30">
                <div class="single-cta">
                  {/* <i class="far fa-envelope-open"></i> */}
                  <Icon className="Icon-Footer" icon={mailIcon} />
                  <div class="cta-text">
                    <h4>Mail us</h4>
                    <a href="mailto:Gleamingfloorscleaning@gmail.com" className="Contact-detial">Gleamingfloorscleaning@gmail.com</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-content pt-5 pb-5">
            <div class="row">
              <div class="col-xl-3 col-lg-4 mb-50">
                <div class="footer-widget">
                  <div class="footer-logo">
                    <a href="index.html">
                      <img src={Logo} class="img-fluid" alt="logo" />
                    </a>
                  </div>
                  <div class="footer-text">
                    <p>
                      Celebrating a decade of excellence, we're your go-to
                      carpet cleaning company. Experience pristine carpets and
                      unrivaled service. Trust us with your home's hygiene.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-4 col-md-6 mb-30">
                <div class="footer-widget">
                  <div class="footer-widget-heading">
                    <h3>Useful Links</h3>
                  </div>
                  <ul>
                    <li>
                      <a className="Tag-ffoter" onClick={Carpetcleaning}>
                        Carpet Cleaning
                      </a>
                    </li>
                    <li>
                      <a className="Tag-ffoter" onClick={VacateCleaning}>
                        Vacate Cleaning
                      </a>
                    </li>
                    <li>
                      <a className="Tag-ffoter" onClick={WindowCleaning}>
                        Window Cleaning
                      </a>
                    </li>
                    <li>
                      <a className="Tag-ffoter" onClick={officecommercial}>
                        Office & Commercial Cleaning
                      </a>
                    </li>
                    <li>
                      <a className="Tag-ffoter" onClick={Groupcleaning}>
                        Grout Cleaning
                      </a>
                    </li>
                    <li>
                      <a className="Tag-ffoter" onClick={Carcarpet}>
                        Car Carpet Seats Cleaning
                      </a>
                    </li>
                    <li>
                      <a className="Tag-ffoter" onClick={Upholsterycleaning}>
                        Upholstery Cleaning
                      </a>
                    </li>
                    <li>
                      <a className="Tag-ffoter" onClick={Rugscleaning}>
                        Rugs Cleaning
                      </a>
                    </li>
                    <li>
                      <a className="Tag-ffoter" onClick={Officecleaning}>
                        Office Carpet Cleaning
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6 mb-30">
                <div class="footer-widget">
                  <div class="footer-widget-heading">
                    <h3>
                      <h3>Subscribe</h3>
                    </h3>
                  </div>
                  <ul>
                    <li>
                      <a className="Tag-ffoter" onClick={Home}>
                        Home
                      </a>
                    </li>
                    <li>
                      <a className="Tag-ffoter" onClick={About}>
                        About Us
                      </a>
                    </li>
                    <li>
                      <a className="Tag-ffoter" onClick={Blogs}>
                        Blogs
                      </a>
                    </li>
                    <li>
                      <a className="Tag-ffoter" onClick={ContactUs}>
                        Contact Us
                      </a>
                    </li>
                  </ul>
                  <div class="footer-social-icon">
                    <span>Follow us</span>
                    <a href="#">
                      <Icon className="Icons83" icon={sharpFacebook} />
                    </a>
                    <a href="#">
                      <Icon className="Icons83" icon={instagramIcon} />
                    </a>
                    <a href="#">
                      <Icon className="Icons83" icon={twitterOutline} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright-area">
          <div class="container">
            <div class="row">
              <div style={{ width: "100%" }}>
                <div class="copyright-text">
                  <p>
                    Copyright &copy; 2023, All Right Reserved by
                    <a href="https://gleamingfloors.com.au"> gleamingfloors</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
