import React, { useEffect, useRef } from "react";
import $ from "jquery";
import "intersection-observer";
import "./Counter.css";

function Counter() {
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            $(".counter").each(function () {
              var $this = $(this),
                countTo = $this.attr("data-count");
              $({ countNum: $this.text() }).animate(
                {
                  countNum: countTo,
                },
                {
                  duration: 8000,
                  easing: "linear",
                  step: function () {
                    $this.text(Math.floor(this.countNum));
                  },
                  complete: function () {
                    $this.text(this.countNum);
                  },
                }
              );
            });
            observer.unobserve(counterRef.current);
          }
        });
      },
      {
        rootMargin: "0px",
        threshold: 0.5,
      }
    );
    observer.observe(counterRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <>
      <div className="Set-Counter">
        {/* <h1 className="Nit-counter">Why Choose Us ?</h1> */}
      </div>
      <div className="Counter-Data" ref={counterRef}>
        <div className="Box-counter">
          <div className="Set-Nice">
            <div className="counter" data-count="9187">
              0
            </div>
            <span className="counter"> +</span>
          </div>
          <p className="counter-text">Customer Satisfaction</p>
        </div>
        <div className="Box-counter">
          <div className="Set-Nice">
            <div className="counter" data-count="8915">
              0
            </div>
            <span className="counter"> +</span>
          </div>
          <p className="counter-text">Eco-Friendly Approach</p>
        </div>{" "}
        <div className="Box-counter">
          <div className="Set-Nice">
            <div className="counter" data-count="6541">
              0
            </div>
            <span className="counter"> +</span>
          </div>
          <p className="counter-text">Safety Measures</p>
        </div>
        <div className="Box-counter">
          <div className="Set-Nice">
            <div className="counter" data-count="1759">
              0
            </div>
            <span className="counter"> +</span>
          </div>
          <p className="counter-text">Call to Action</p>
        </div>
      </div>
    </>
  );
}

export default Counter;
