import React, { useEffect } from "react";
import "./Services.css";
import { Icon } from "@iconify/react";
import check2Line from "@iconify/icons-mingcute/check-2-line";
import RSMain from "./images/Services/img3.png";
import Ser9 from "./images/img9.png";
import Ser10 from "./images/img10.png";
import Ser11 from "./images/img11.png";
import Ser12 from "./images/img12.png";
import Ser13 from "./images/img13.png";
import Ser14 from "./images/img14.png";
import Ban9 from "./images/Group2.png";
import Footer from "../Footer/Footer";
import Newserc from "./images/Services/img4.png";

function Window() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, []);
  return (
    <>
      <div className="Dammy"></div>
      <div className="Banner-all">
        <h2 className="Ba-ab-set">Window Cleaning</h2>
      </div>
      <div className="Text-img-ser">
        <div className="SText-contaner">
          <div className="Blocl">
            <h1 className="setup-text">Budget-Friendly Window Cleaning</h1>
            <p className="Stp098">
              Discover our budget-friendly window cleaning services designed to
              bring sparkling clarity to your home or office. We offer a
              cost-effective solution without compromising on the quality of
              service, ensuring your windows gleam with a pristine shine.<br/>
              Experience the beauty of a crystal-clear view through spotless windows, all while staying within your budget.
            </p>
          </div>
          <div className="set-checks">
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Clear Views
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Clear Savings
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Affordable Sparkle
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Bringing Sunshine
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Budget-Savvy
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              See the World Clearly
            </p>
          </div>
        </div>
        <div className="SImg-con">
          <img className="imgs-set" src={RSMain} alt="" />
        </div>
      </div>
      <div className="Ser-section-aed">
        <div className="Ser-bsu">
          <img className="Set-fjimg" src={Newserc} alt="" />
        </div>
        <div className="Ser-bsu">
          <h2 className="setupn-text">
          Our Window Cleaning Services
          </h2>
          <p className="Stp098">
          The window cleaning service that we at Gleaming Cleaning Group offer is highly demanded since:
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
            We deliver immaculate, streak-free windows every time.
            </p>
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
              We are committed to environmentally responsible cleaning. Our use
              of eco-friendly products and methods not only ensures the safety
              of your family and pets
            </p>
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
              Our state-of-the-art equipment and advanced processes enable rapid
              drying of your carpets. get back to enjoying your fresh, clean
              carpets witFhout the inconvenience
            </p>
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
            You can schedule window cleaning services at your convenience, whether it's a one-time cleaning or a regular maintenance plan.
            </p>
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
            Our window cleaning services are competitively priced, making them accessible to a wide range of customers.
            </p>
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
              We're available for emergency window cleaning services to meet your immediate needs.
            </p>
          </p>
        </div>
      </div>
      <div className="Frendly">
        <div className="Set-Secet">
          <h1 className="TExt-secret">
            Fast, Friendly, and Satisfaction Guarantee
          </h1>
          <p className="Min-texsecre">
            Experience fast and friendly carpet cleaning with our satisfaction
            guarantee. We promise a fresh, clean carpet that meets your highest
            expectations.
          </p>
        </div>
        <div className="Stser982">
          <div className="Set-1-ser">
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser9} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Satisfaction Guarantee</h2>
                <p className="set-Lodw3">
                  Our cleaning service comes with a satisfaction guarantee: if
                  you're not happy, we'll make it right.
                </p>
              </div>
            </div>
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser10} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Local Professionals</h2>
                <p className="set-Lodw3">
                  Trust your home to local cleaning professionals who know your
                  area best. Experience quality service from your community
                  experts.
                </p>
              </div>
            </div>
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser11} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Flexible Appointments</h2>
                <p className="set-Lodw3">
                  Enjoy the convenience of flexible appointments with our
                  cleaning service, tailored to your schedule and needs.
                </p>
              </div>
            </div>
          </div>
          <div className="Set-1-ser">
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser12} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Free Quotes</h2>
                <p className="set-Lodw3">
                  Request your free cleaning service quote today, and discover
                  our competitive rates to keep your space spotless!
                </p>
              </div>
            </div>
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser13} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Fast 24-Hour Service</h2>
                <p className="set-Lodw3">
                  Experience our lightning-fast 24-hour cleaning service, ready
                  to tackle any cleaning challenge around the clock
                </p>
              </div>
            </div>
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser14} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">100% Commitment-Free</h2>
                <p className="set-Lodw3">
                  Our cleaning service offers a 100% commitment-free
                  consultation, so you can explore your options with no
                  obligations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ST-baneuy875">
        <div className="Ban98-img">
          <img className="HDf9826" src={Ban9} alt="" />
        </div>
        <div className="Ban98-img">
          <h1 className="set-text09">How Home Pro works?</h1>
          <div className="Line-one">
            <p className="Linumber">1.</p>
            <p className="LiHedcont">Estimate and Assessment</p>
            <p className="LINContent">
            based on the customer's description of the number and size of windows.
            </p>
          </div>
          <div className="Line-one">
            <p className="Linumber">2.</p>
            <p className="LiHedcont">Pre-Treatment</p>
            <p className="LINContent">
            Customers can customize the service according to their specific needs and budget.
            </p>
          </div>
          <div className="Line-one">
            <p className="Linumber">3.</p>
            <p className="LiHedcont">Customized Service</p>
            <p className="LINContent">
            the cleaned windows and provide their approval before the team leaves.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Window