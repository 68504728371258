import React, { useEffect } from "react";
import "./Services.css";
import { Icon } from "@iconify/react";
import check2Line from "@iconify/icons-mingcute/check-2-line";
import RSMain from "./images/Services/img1.png";
import Ser9 from "./images/img9.png";
import Ser10 from "./images/img10.png";
import Ser11 from "./images/img11.png";
import Ser12 from "./images/img12.png";
import Ser13 from "./images/img13.png";
import Ser14 from "./images/img14.png";
import Ban9 from "./images/Group1.png";
import Footer from "../Footer/Footer";
import Newserc from "./images/Services/img2.png";

function Commercial() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="Dammy"></div>
      <div className="Banner-all">
        <h2 className="Ba-ab-set">Vacate Cleaning</h2>
      </div>
      <div className="Text-img-ser">
        <div className="SText-contaner">
          <div className="Blocl">
            <h1 className="setup-text">
              Expert Vacate Cleaning for a Smooth Move-Out
            </h1>
            <p className="Stp098">
              Trust our expert vacate cleaning services to ensure a hassle-free
              move-out experience. We meticulously clean every nook and cranny,
              leaving your space in pristine condition. Our experienced team
              knows what landlords and property managers expect, and we're
              committed to meeting those standards. Let us handle the cleaning,
              so you can focus on your transition with peace of mind
            </p>
          </div>
          <div className="set-checks">
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Moving Out?
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Vacate Cleaning
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Leave No Trace
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              From Chaos to Clarity
                          </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Maximize Deposit
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              
              Smooth Move-Out
            </p>
          </div>
        </div>
        <div className="SImg-con">
          <img className="imgs-set" src={RSMain} alt="" />
        </div>
      </div>
      <div className="Ser-section-aed">
        <div className="Ser-bsu">
          <img className="Set-fjimg" src={Newserc} alt="" />
        </div>
        <div className="Ser-bsu">
          <h2 className="setupn-text">
          Our Vacate Cleaning Service Includes
          </h2>
          <p className="Stp098">
          cleaning of all rooms, floor cleaning, sanitization of high-touch surfaces.
                    </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
            Removing trash and ensuring a clean, refreshed living space.
            </p>
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
            Thorough cleaning of all rooms, including bedrooms, living areas, kitchens, and bathrooms.
            </p>
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
            Cleaning and sanitizing of kitchen appliances such as the oven, microwave, and refrigerator.
            </p>
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
            Accommodating your move-out timeline and preferences for scheduling the cleaning service.
            </p>
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
            Vacuuming and mopping of all floors, carpets, and tiles and Wiping down and cleaning of interior windows and glass surfaces.
            </p>
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
            Cleaning and dusting of baseboards and trims and Dusting and cleaning of light fixtures and ceiling fans.
            </p>
          </p>
        </div>
      </div>
      <div className="Frendly">
        <div className="Set-Secet">
          <h1 className="TExt-secret">
          Fast, Friendly, and Satisfaction Guarantee
          </h1>
          <p className="Min-texsecre">
            Experience fast and friendly carpet cleaning with our satisfaction
            guarantee. We promise a fresh, clean carpet that meets your highest
            expectations.
          </p>
        </div>
        <div className="Stser982">
          <div className="Set-1-ser">
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser9} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Satisfaction Guarantee</h2>
                <p className="set-Lodw3">
                  Our cleaning service comes with a satisfaction guarantee: if
                  you're not happy, we'll make it right.
                </p>
              </div>
            </div>
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser10} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Local Professionals</h2>
                <p className="set-Lodw3">
                  Trust your home to local cleaning professionals who know your
                  area best. Experience quality service from your community
                  experts.
                </p>
              </div>
            </div>
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser11} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Flexible Appointments</h2>
                <p className="set-Lodw3">
                  Enjoy the convenience of flexible appointments with our
                  cleaning service, tailored to your schedule and needs.
                </p>
              </div>
            </div>
          </div>
          <div className="Set-1-ser">
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser12} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Free Quotes</h2>
                <p className="set-Lodw3">
                  Request your free cleaning service quote today, and discover
                  our competitive rates to keep your space spotless!
                </p>
              </div>
            </div>
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser13} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Fast 24-Hour Service</h2>
                <p className="set-Lodw3">
                  Experience our lightning-fast 24-hour cleaning service, ready
                  to tackle any cleaning challenge around the clock
                </p>
              </div>
            </div>
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser14} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">100% Commitment-Free</h2>
                <p className="set-Lodw3">
                  Our cleaning service offers a 100% commitment-free
                  consultation, so you can explore your options with no
                  obligations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ST-baneuy875">
        <div className="Ban98-img">
          <img className="HDf9826" src={Ban9} alt="" />
        </div>
        <div className="Ban98-img">
          <h1 className="set-text09">How Home Pro works?</h1>
          <div className="Line-one">
            <p className="Linumber">1.</p>
            <p className="LiHedcont">Professional Cleaning</p>
            <p className="LINContent">
            HomePro sends a professional cleaning team to the property on the scheduled date.
            </p>
          </div>
          <div className="Line-one">
            <p className="Linumber">2.</p>
            <p className="LiHedcont">Thorough Cleaning</p>
            <p className="LINContent">
            including bedrooms, living areas, kitchens, bathrooms, and other spaces.
            </p>
          </div>
          <div className="Line-one">
            <p className="Linumber">3.</p>
            <p className="LiHedcont">Quality Assurance</p>
            <p className="LINContent">
            HomePro maintains high standards of quality and professionalism, ensuring that every cleaning task
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Commercial;
