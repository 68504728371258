import React from "react";
import "./Banner.css";
import Layout from "../IMG/Layouyt.png";
import Service1 from "../IMG/Services1.png";
import Service2 from "../IMG/Services2.png";
import Service3 from "../IMG/Services3.png";
import Service4 from "../IMG/Services4.png";
import Service5 from "../IMG/Services5.png";
import Service6 from "../IMG/Services6.jpg";
import Service7 from "../IMG/Services7.png";
import Service8 from "../IMG/Services8.png";
import Service9 from "../IMG/Services9.png";
import { useNavigate } from "react-router-dom";

function Banner() {
  const navigate = useNavigate();

  const Carpetcleaning = () => {
    navigate("/CarpetCleaning");
  };
  const VacateCleaning = () => {
    navigate("/VacateCleaning");
  };
  const WindowCleaning = () => {
    navigate("/WindowCleaning");
  };
  const officecommercial = () => {
    navigate("/Office");
  };
  const Groupcleaning = () => {
    navigate("/Grout");
  };
  const Carcarpet = () => {
    navigate("/Pressurecleaning");
  };
  const Upholsterycleaning = () => {
    navigate("/Upholstery");
  };
  const Rugscleaning = () => {
    navigate("/Rugs");
  };
  const Officecleaning = () => {
    navigate("/OfficeCarpet");
  };
  const About = () => {
    navigate("/about");
  };
  const Contact = () => {
    navigate("/Contact");
  };

  return (
    <>
      <div className="Dammy"></div>
      <div className="Banner-img">
        <div className="Contenr">
          <p className="BanText">COMMITTED TO TOP QUALTY SERVICES</p>
          <p className="BanHedText">
            Professional Residetial
            <br /> And Commercial Cleaning Services
          </p>
          <button onClick={Contact} className="Btn-Ban">
            Book Now
          </button>
        </div>
      </div>
      <div className="Layout-Con">
        <div className="Lay-Img">
          <img className="Img-l" src={Layout} alt="" />
        </div>
        <div className="Lay-Cont">
          <p className="Lay-canrte">About Us</p>
          <p className="Lay-Teke">
            Your trusted carpet cleaning experts in Australia.
          </p>
          <p className="Lay-sub">
            We are your trusted carpet cleaning experts in Australia, with
            5years of experience and a commitment to delivering impeccable
            results.
          </p>
          <p className="Lay-sub">
            Our professional team uses advanced techniques and eco-friendly
            solutions to rejuvenate your carpets, leaving them fresh, clean, and
            allergen-free. Contact us today for a cleaner, healthier home or
            workspace.
          </p>
          <button onClick={About} className="Btn-Ban">
            Learn More
          </button>
        </div>
      </div>
      <div className="service-contener">
        <p className="Set-lay">Our Feachered Services</p>
        <h2 className="teg-line">
          We provide some <span className="New-Color">exclusive</span>
          <br /> services for clients
        </h2>
        <div className="Services-cards">
          <div className="Ser-card">
            <img className="Ser-Img90" src={Service1} alt="" />
            <h2 className="Ser-head">Carpet cleaning services</h2>
            <p className="Ser-Description">
              Carpet cleaning services are professional cleaning services that
              remove dirt, stains, and allergens.
            </p>
            <button onClick={Carpetcleaning} className="Btn-Services">
              Read More
            </button>
          </div>
          <div className="Ser-card">
            <img className="Ser-Img90" src={Service2} alt="" />
            <h2 className="Ser-head">Vacate Cleaning services</h2>
            <p className="Ser-Description">
              Vacate cleaning services ensure a thorough cleaning of a property
              when tenants move out.
            </p>
            <button onClick={VacateCleaning} className="Btn-Services">
              Read More
            </button>
          </div>
          <div className="Ser-card">
            <img className="Ser-Img90" src={Service3} alt="" />
            <h2 className="Ser-head">Window Cleaning services</h2>
            <p className="Ser-Description">
              Window cleaning involves the professional cleaning of windows,
              removing dirt, streaks, and grime.
            </p>
            <button onClick={WindowCleaning} className="Btn-Services">
              Read More
            </button>
          </div>
          <div className="Ser-card">
            <img className="Ser-Img90" src={Service4} alt="" />
            <h2 className="Ser-head">
              office and commercial Cleaning services
            </h2>
            <p className="Ser-Description">
              Office and commercial cleaning services maintain clean and
              hygienic workspaces by providing regular cleaning.
            </p>
            <button onClick={officecommercial} className="Btn-Services">
              Read More
            </button>
          </div>
          <div className="Ser-card">
            <img className="Ser-Img90" src={Service5} alt="" />
            <h2 className="Ser-head">Grout cleaning services</h2>
            <p className="Ser-Description">
              Revive your tiles with our expert grout cleaning service. We
              remove dirt and stains, leaving your space fresh and vibrant.
            </p>
            <button onClick={Groupcleaning} className="Btn-Services">
              Read More
            </button>
          </div>
          <div className="Ser-card">
            <img className="Ser-Img90" src={Service6} alt="" />
            <h2 className="Ser-head">Pressure cleaning</h2>
            <p className="Ser-Description">
              Renew the beauty of your surfaces. Our pressure cleaning service
              blasts away dirt, grime, and stains, revealing a fresh, clean
              look.
            </p>
            <button onClick={Carcarpet} className="Btn-Services">
              Read More
            </button>
          </div>
          <div className="Ser-card">
            <img className="Ser-Img90" src={Service7} alt="" />
            <h2 className="Ser-head">Upholstery cleaning services</h2>
            <p className="Ser-Description">
              Upholstery cleaning services focus on professionally cleaning and
              revitalizing furniture fabrics.
            </p>
            <button onClick={Upholsterycleaning} className="Btn-Services">
              Read More
            </button>
          </div>
          <div className="Ser-card">
            <img className="Ser-Img90" src={Service8} alt="" />
            <h2 className="Ser-head">Rugs cleaning services</h2>
            <p className="Ser-Description">
              Rug cleaning services specialize in professionally cleaning and
              restoring area rugs to remove stains.
            </p>
            <button onCanPlay={Rugscleaning} className="Btn-Services">
              Read More
            </button>
          </div>
          <div className="Ser-card">
            <img className="Ser-Img90" src={Service9} alt="" />
            <h2 className="Ser-head">Office carpet cleaning services</h2>
            <p className="Ser-Description">
              Office carpet cleaning specialized cleaning providers focused on
              professional appearance for commercial.
            </p>
            <button onClick={Officecleaning} className="Btn-Services">
              Read More
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
