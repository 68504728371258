import React, { useEffect } from "react";
import "./AboutUs.css";
import Ban from "./Img/AboutUs.svg";
import Set from "./Img/Group70.png";
import Step1 from "./Img/Setp1.png";
import Step2 from "./Img/Step2.png";
import Step3 from "./Img/Step3.png";
import Step4 from "./Img/step4.png";
import Counter from "./Counter/Counter";
import Footer from "../Footer/Footer";
import Added from "./Img/added.png";

function AboutUs() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="Dammy"></div>
      <div className="Banner-all">
        <h2 className="Ba-ab-set">Aboutus</h2>
      </div>
      <div className="About-top">
        <div className="Top-Main">
          <img className="img-top" src={Ban} alt="" />
        </div>
        <div className="Top-Main">
          <h1 className="Tetx-ab">
            Your trusted carpet cleaning experts in Australia.
          </h1>
          <p className="Ab-content">
            We are the trusted carpet cleaning experts in Australia, delivering
            impeccable service and rejuvenating your carpets to perfection.
            Experience cleaner, fresher, and healthier living spaces with us.
          </p>
        </div>
      </div>
      <div className="Black-ab">
        <div className="setup-ab">
          <div className="Ab-oin">
            <p className="sert752">Who we are</p>
            <h1 className="Mainten-ab">About us</h1>
            <p className="ab-subline">
              At Gleaming Carpet Cleaning Services, we're more than just a
              cleaning company; we're your trusted partners in maintaining a
              clean and healthy environment.
            </p>
            <p className="ab-subline">
              With years of experience in the industry, we have established
              ourselves as a premier cleaning service provider.
            </p>
            <p className="ab-subline">
              Count on us for exceptional service, attention to detail, and a
              commitment to customer satisfaction in every carpet cleaning
              project.
            </p>
          </div>
          <div className="Ab-oin2">
            <img className="Set-img-ab" src={Set} alt="" />
          </div>
        </div>
      </div>
      <div className="add-section">
        <div className="Set-douy1">
          <img className="JS028" src={Added} alt="" />
        </div>
        <div className="Set-douy2">
          <h1 className="Shjgf37">
            We are your premier choice for all-encompassing cleaning solutions.
          </h1>
          <p className="Add-desrciption">
            At our company, we take pride in being your top choice for
            comprehensive cleaning solutions. We offer a wide range of services
            that cater to all your cleaning needs, ensuring your spaces are
            spotless and refreshed. Whether it's residential or commercial
            cleaning, carpet and upholstery cleaning, or deep cleaning services,
            we're dedicated to delivering impeccable results. With our
            experienced team and top-notch equipment, you can trust us to
            transform your surroundings into clean, inviting spaces.
            <br />
            <br />
          </p>
          <p className="Add-desrciption">
            Choose us as your cleaning partner, and experience the difference in
            cleanliness and freshness that only our premier services can
            provide.
          </p>
        </div>
      </div>
      <div className="Steap-min">
        <h1 className="Mainten-ab">Some easy steps</h1>
        <p className="Main-sub-text">
          Cleanse carpets effectively with these steps: assess, vacuum,
          pre-treat, steam clean, spot treat, agitate, rinse, post-treat
          (optional), dry, inspect, and advise on maintenance.
        </p>
        <div className="Step-contenters">
          <div className="Steps-type">
            <div className="img-setp">
              <img className="Img-Setaf61" src={Step1} alt="" />
            </div>
            <div className="Contener-setp">
              <h1 className="Setups082">Assessment and Quotation</h1>
              <p className="Setp-cont">
              We'll provide you with a transparent and competitive quotation based on your needs and the size of the area to be cleaned.
              </p>
            </div>
          </div>

          <div className="Steps-type">
            <div className="img-setp">
              <img className="Img-Setaf61" src={Step2} alt="" />
            </div>
            <div className="Contener-setp">
              <h1 className="Setups082">Schedule Your Cleaning</h1>
              <p className="Setp-cont">
              Once you're satisfied with the quote, we'll work with you to set a convenient date and time for the cleaning service.
              </p>
            </div>
          </div>
          <div className="Steps-type">
            <div className="img-setp">
              <img className="Img-Setaf61" src={Step3} alt="" />
            </div>
            <div className="Contener-setp">
              <h1 className="Setups082">Feedback and Follow-up</h1>
              <p className="Setp-cont">
              We may also follow up with you to ensure you're happy with the results and to address any additional questions or concerns.
              </p>
            </div>
          </div>
          <div className="Steps-type">
            <div className="img-setp">
              <img className="Img-Setaf61" src={Step4} alt="" />
            </div>
            <div className="Contener-setp">
              <h1 className="Setups082">Enjoy Your Clean Carpets</h1>
              <p className="Setp-cont">
              With our professional carpet cleaning service, you can now enjoy clean, fresh, and revitalized carpets.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Counter />
      <Footer />
    </>
  );
}

export default AboutUs;
