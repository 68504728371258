import React, { useEffect } from "react";
import "./Services.css";
import { Icon } from "@iconify/react";
import check2Line from "@iconify/icons-mingcute/check-2-line";
import RSMain from "./images/Services/img9.jpg";
import Ser9 from "./images/img9.png";
import Ser10 from "./images/img10.png";
import Ser11 from "./images/img11.png";
import Ser12 from "./images/img12.png";
import Ser13 from "./images/img13.png";
import Ser14 from "./images/img14.png";
import Ban9 from "./images/Group5.png";
import Footer from "../Footer/Footer";
import Newserc from "./images/Services/img10.jpg";

function Car() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="Dammy"></div>
      <div className="Banner-all">
        <h2 className="Ba-ab-set">Pressure Cleaning</h2>
      </div>
      <div className="Text-img-ser">
        <div className="SText-contaner">
          <div className="Blocl">
            <h1 className="setup-text">
              Cleansing the Unseen: The Power of Pressure Cleaning
            </h1>
            <p className="Stp098">
              Our pressure cleaning service is your ticket to a cleaner,
              brighter environment. We use high-pressure techniques to eliminate
              stubborn grime, mold, and dirt from surfaces. Revive your home or
              business's exterior, sidewalks, decks, and more, with our
              professional pressure cleaning. Experience the transformative
              power of pressure cleaning today!
              {/* <br />
              Say goodbye to stains, odors, and dirt, and enjoy the comfort of a
              spotless. */}
            </p>
          </div>
          <div className="set-checks">
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Deep Cleaning
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Enhanced Curb Appeal
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Preventative Maintenance
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Healthier Environment
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Time and Cost Efficiency
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Eco-Friendly
            </p>
          </div>
        </div>
        <div className="SImg-con">
          <img className="imgs-set" src={RSMain} alt="" />
        </div>
      </div>
      <div className="Ser-section-aed">
        <div className="Ser-bsu">
          <img className="Set-fjimg" src={Newserc} alt="" />
        </div>
        <div className="Ser-bsu">
          <h2 className="setupn-text">
            Professional Pressure Cleaning Services for a Brighter, Cleaner
            Environment.
          </h2>
          <p className="Stp098">
            Pressure cleaning, also known as power washing, is a method of using
            high-pressure water to remove dirt, grime, mold, and other
            contaminants from various surfaces.
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
              Different nozzles create various spray patterns for specific
              cleaning tasks.
            </p>
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
              High-pressure water method for removing dirt and grime from
              surfaces, including driveways and decks.
            </p>
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
              Avoid damage, protect people, pets, and windows, wear safety gear,
              and follow manufacturer instructions.
            </p>
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
              Versatile, powerful method for maintaining property cleanliness
              and value. Prioritize safety and follow manufacturer guidelines.
            </p>
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
              We offer flexible scheduling options, making it easy for you to
              choose a time that suits your busy lifestyle.
            </p>
          </p>

          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
              We use environmentally responsible products for a safe and green
              cleaning process.
            </p>
          </p>
        </div>
      </div>
      <div className="Frendly">
        <div className="Set-Secet">
          <h1 className="TExt-secret">
            Fast, Friendly, and Satisfaction Guarantee
          </h1>
          <p className="Min-texsecre">
            Experience fast and friendly carpet cleaning with our satisfaction
            guarantee. We promise a fresh, clean carpet that meets your highest
            expectations.
          </p>
        </div>
        <div className="Stser982">
          <div className="Set-1-ser">
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser9} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Satisfaction Guarantee</h2>
                <p className="set-Lodw3">
                  Our cleaning service comes with a satisfaction guarantee: if
                  you're not happy, we'll make it right.
                </p>
              </div>
            </div>
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser10} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Local Professionals</h2>
                <p className="set-Lodw3">
                  Trust your home to local cleaning professionals who know your
                  area best. Experience quality service from your community
                  experts.
                </p>
              </div>
            </div>
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser11} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Flexible Appointments</h2>
                <p className="set-Lodw3">
                  Enjoy the convenience of flexible appointments with our
                  cleaning service, tailored to your schedule and needs.
                </p>
              </div>
            </div>
          </div>
          <div className="Set-1-ser">
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser12} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Free Quotes</h2>
                <p className="set-Lodw3">
                  Request your free cleaning service quote today, and discover
                  our competitive rates to keep your space spotless!
                </p>
              </div>
            </div>
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser13} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Fast 24-Hour Service</h2>
                <p className="set-Lodw3">
                  Experience our lightning-fast 24-hour cleaning service, ready
                  to tackle any cleaning challenge around the clock
                </p>
              </div>
            </div>
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser14} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">100% Commitment-Free</h2>
                <p className="set-Lodw3">
                  Our cleaning service offers a 100% commitment-free
                  consultation, so you can explore your options with no
                  obligations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ST-baneuy875">
        <div className="Ban98-img">
          <img className="HDf9826" src={Ban9} alt="" />
        </div>
        <div className="Ban98-img">
          <h1 className="set-text09">How Home Pro works?</h1>
          <div className="Line-one">
            <p className="Linumber">1.</p>
            <p className="LiHedcont">Assessment and Quotation</p>
            <p className="LINContent">
              Home pros typically begin by assessing the cleaning requirements.
            </p>
          </div>
          <div className="Line-one">
            <p className="Linumber">2.</p>
            <p className="LiHedcont">Professional Cleaning</p>
            <p className="LINContent">
              Home pros use their expertise to select the appropriate pressure,
              nozzles, and cleaning solutions for each surface.
            </p>
          </div>
          <div className="Line-one">
            <p className="Linumber">3.</p>
            <p className="LiHedcont">Quality Assurance </p>
            <p className="LINContent">
              After completing the cleaning, home pros conduct a quality check
              to ensure the desired results. They may revisit any problem areas.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Car;
