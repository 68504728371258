import React, { useEffect } from "react";
import "./Home.css";
import Banner from "./Banner/Banner";
import Testimonials from "./Banner/Testimonials/Testimonial";
import Footer from "../Footer/Footer";

function Home() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Banner />
      <Testimonials />
      <Footer />
    </>
  );
}

export default Home;
