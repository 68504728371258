import React, { useEffect } from "react";
import "./Services.css";
import { Icon } from "@iconify/react";
import check2Line from "@iconify/icons-mingcute/check-2-line";
import RSMain from "./images/Services/img5.png";
import Ser9 from "./images/img9.png";
import Ser10 from "./images/img10.png";
import Ser11 from "./images/img11.png";
import Ser12 from "./images/img12.png";
import Ser13 from "./images/img13.png";
import Ser14 from "./images/img14.png";
import Ban9 from "./images/Group3.png";
import Footer from "../Footer/Footer";
import Newserc from "./images/Services/img6.png";

function Office() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="Dammy"></div>
      <div className="Banner-all">
        <h2 className="Ba-ab-set">Office & Commercial Cleaning</h2>
      </div>
      <div className="Text-img-ser">
        <div className="SText-contaner">
          <div className="Blocl">
            <h1 className="setup-text">Office and Commercial Cleaning</h1>
            <p className="Stp098">
              Our Office and Commercial Cleaning Services are tailored to meet
              the unique demands of business spaces. We ensure a pristine and
              sanitary environment for your employees and clients. Our
              experienced team employs efficient cleaning practices, adheres to
              industry standards, and uses eco-friendly products.
              <br />
              Elevate your workspace with our comprehensive Office and
              Commercial Cleaning Services. We specialize in maintaining a clean
              and professional environment
            </p>
          </div>
          <div className="set-checks">
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Clear Views
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Clear Savings
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Affordable Sparkle
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Bringing Sunshine
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              Budget-Savvy
            </p>
            <p className="Box-check">
              <Icon className="Icon-check" icon={check2Line} />
              See the World Clearly
            </p>
          </div>
        </div>
        <div className="SImg-con">
          <img className="imgs-set" src={RSMain} alt="" />
        </div>
      </div>
      <div className="Ser-section-aed">
        <div className="Ser-bsu">
          <img className="Set-fjimg" src={Newserc} alt="" />
        </div>
        <div className="Ser-bsu">
          <h2 className="setupn-text">
          Choose Office Cleaning Services Commercial Cleaning Service
          </h2>
          <p className="Stp098">
          Certainly, when selecting office cleaning services, it's essential to consider a few key factors to ensure you make the right choice.
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
            sensitive equipment, high-traffic areas, or COVID-19 sanitation
            </p>
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
            Set a budget for your office cleaning services. Consider how frequently you need cleaning.
            </p>
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
            Ensure that the cleaning companies you're considering have the necessary licenses, insurance, and certifications
            </p>
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
              We specialize in effective stain and odor removal, addressing
              common issues such as pet stains, food spills, and persistent
              odors.
            </p>
          </p>
          <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
            Read online reviews and ask the cleaning companies for references from their current clients to get an idea?
            </p>
          </p>
          {/* <p className="With-text-area">
            <Icon className="Icon-check-ser" icon={check2Line} />
            <p className="For-addign">
              We understand that every carpet is unique. Our team tailors the
              cleaning process to meet the specific needs of your carpets.
            </p>
          </p> */}
        </div>
      </div>
      <div className="Frendly">
        <div className="Set-Secet">
          <h1 className="TExt-secret">
            Fast, Friendly, and Satisfaction Guarantee
          </h1>
          <p className="Min-texsecre">
            Experience fast and friendly carpet cleaning with our satisfaction
            guarantee. We promise a fresh, clean carpet that meets your highest
            expectations.
          </p>
        </div>
        <div className="Stser982">
          <div className="Set-1-ser">
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser9} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Satisfaction Guarantee</h2>
                <p className="set-Lodw3">
                  Our cleaning service comes with a satisfaction guarantee: if
                  you're not happy, we'll make it right.
                </p>
              </div>
            </div>
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser10} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Local Professionals</h2>
                <p className="set-Lodw3">
                  Trust your home to local cleaning professionals who know your
                  area best. Experience quality service from your community
                  experts.
                </p>
              </div>
            </div>
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser11} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Flexible Appointments</h2>
                <p className="set-Lodw3">
                  Enjoy the convenience of flexible appointments with our
                  cleaning service, tailored to your schedule and needs.
                </p>
              </div>
            </div>
          </div>
          <div className="Set-1-ser">
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser12} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Free Quotes</h2>
                <p className="set-Lodw3">
                  Request your free cleaning service quote today, and discover
                  our competitive rates to keep your space spotless!
                </p>
              </div>
            </div>
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser13} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">Fast 24-Hour Service</h2>
                <p className="set-Lodw3">
                  Experience our lightning-fast 24-hour cleaning service, ready
                  to tackle any cleaning challenge around the clock
                </p>
              </div>
            </div>
            <div className="Hanekl-seyh">
              <div className="Dk-img">
                <img className="ser-img98" src={Ser14} alt="" />
              </div>
              <div className="DK-secno">
                <h2 className="set-ki092">100% Commitment-Free</h2>
                <p className="set-Lodw3">
                  Our cleaning service offers a 100% commitment-free
                  consultation, so you can explore your options with no
                  obligations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ST-baneuy875">
        <div className="Ban98-img">
          <img className="HDf9826" src={Ban9} alt="" />
        </div>
        <div className="Ban98-img">
          <h1 className="set-text09">How Home Pro works?</h1>
          <div className="Line-one">
            <p className="Linumber">1.</p>
            <p className="LiHedcont">Security and Safety</p>
            <p className="LINContent">
            ensuring the safety of the client's property, sensitive information, and valuable equipment.
            </p>
          </div>
          <div className="Line-one">
            <p className="Linumber">2.</p>
            <p className="LiHedcont">Invoice and Payment</p>
            <p className="LINContent">
            Clients can make payments using various convenient methods.
            </p>
          </div>
          <div className="Line-one">
            <p className="Linumber">3.</p>
            <p className="LiHedcont">Emergency Services</p>
            <p className="LINContent">
            emergency cleaning services to address unexpected situations or special cleaning needs.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Office;
