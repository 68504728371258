import React, { useRef, useEffect } from "react";
import "./Testimonials.css";
import Video from "./Benner.mp4";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Icon } from "@iconify/react";
import starIcon from "@iconify/icons-ion/star";
import Client1 from "./Client1.png";

function Testimonial() {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    if (video && isIOS) {
      video.muted = true;

      const playVideo = () => {
        video
          .play()
          .then(() => {
            video.removeEventListener("click", playVideo);
          })
          .catch((error) => {
            console.error("Error playing video:", error);
          });
      };

      video.addEventListener("click", playVideo);
    }
  }, []);
  const settings2 = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3, // Set to 2 to display 2 slides at a time
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="Testimonials">
        <p className="teti-text">PLAY THIS VIDEO TO HOW MAKE IT</p>
        <p className="Textim-text">
          Click on this video to check how
          <br /> we work with our clients
        </p>
        <video
          className="videoTag"
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={Video} type="video/mp4" />
        </video>
      </div>

      {/* Testimonials Start */}
      <div className="Test-Boundary">
        <p className="teti-text">CLIENT FEEDBACK</p>
        <h1 className="Textim-text">
          We have many good
          <br /> <span className="New-Color">client’s</span> review
        </h1>

        <Slider className="slider2" {...settings2}>
          <div className="Client-slider">
            <div className="Client-Main-Compo">
              <div className="Clinet-content">
                <p className="Main-Content">
                  Our carpets look brand new! Exceptional service and attention
                  to detail. They truly bring life back to our home.
                </p>
                {/* <img className="Client" src={Client1} alt="" /> */}
                <div className="Reting">
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                </div>
                <p className="Clinet-name">Meera Singh</p>
                <p className="Designation">Client</p>
              </div>
            </div>
          </div>
          <div className="Client-slider">
            <div className="Client-Main-Compo">
              <div className="Clinet-content">
                <p className="Main-Content">
                  Saved us time and stress during our move. The place was
                  spotless, ensuring we received our full bond refund.
                </p>
                {/* <img className="Client" src={Client1} alt="" /> */}
                <div className="Reting">
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                </div>
                <p className="Clinet-name">Sarah Wilson</p>
                <p className="Designation">Client</p>
              </div>
            </div>
          </div>
          <div className="Client-slider">
            <div className="Client-Main-Compo">
              <div className="Clinet-content">
                <p className="Main-Content">
                  Crystal clear windows and a sparkling view. Their team is
                  efficient and professional, exceeding our expectations.
                </p>
                {/* <img className="Client" src={Client1} alt="" /> */}
                <div className="Reting">
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                </div>
                <p className="Clinet-name">Aarav Kapoor</p>
                <p className="Designation">Client</p>
              </div>
            </div>
          </div>
          <div className="Client-slider">
            <div className="Client-Main-Compo">
              <div className="Clinet-content">
                <p className="Main-Content">
                  An immaculate workspace. They ensure our office is not only
                  clean but also safe. Highly recommend their services.
                </p>
                {/* <img className="Client" src={Client1} alt="" /> */}
                <div className="Reting">
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                </div>
                <p className="Clinet-name">Tom Anderson</p>
                <p className="Designation">Client</p>
              </div>
            </div>
          </div>
          <div className="Client-slider">
            <div className="Client-Main-Compo">
              <div className="Clinet-content">
                <p className="Main-Content">
                  They handle large projects seamlessly. Our event venue has
                  never looked better. Professional and reliable.
                </p>
                {/* <img className="Client" src={Client1} alt="" /> */}
                <div className="Reting">
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                </div>
                <p className="Clinet-name">Sanjana Gupta</p>
                <p className="Designation">Client</p>
              </div>
            </div>
          </div>
          <div className="Client-slider">
            <div className="Client-Main-Compo">
              <div className="Clinet-content">
                <p className="Main-Content">
                  Our car feels like new again. The carpets are spotless and
                  smell fantastic. Great job!
                </p>
                {/* <img className="Client" src={Client1} alt="" /> */}
                <div className="Reting">
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                </div>
                <p className="Clinet-name">Ravi Sharma</p>
                <p className="Designation">Client</p>
              </div>
            </div>
          </div>
          <div className="Client-slider">
            <div className="Client-Main-Compo">
              <div className="Clinet-content">
                <p className="Main-Content">
                  They worked wonders on our furniture. Stains vanished, and the
                  upholstery looks refreshed.
                </p>
                {/* <img className="Client" src={Client1} alt="" /> */}
                <div className="Reting">
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                </div>
                <p className="Clinet-name">James Mitchell</p>
                <p className="Designation">Client</p>
              </div>
            </div>
          </div>
          <div className="Client-slider">
            <div className="Client-Main-Compo">
              <div className="Clinet-content">
                <p className="Main-Content">
                  Our rugs now look vibrant and clean. The team was punctual,
                  efficient, and thorough.
                </p>
                {/* <img className="Client" src={Client1} alt="" /> */}
                <div className="Reting">
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                </div>
                <p className="Clinet-name">Chloe Taylor</p>
                <p className="Designation">Client</p>
              </div>
            </div>
          </div>
          <div className="Client-slider">
            <div className="Client-Main-Compo">
              <div className="Clinet-content">
                <p className="Main-Content">
                  Our office space is transformed after their cleaning. Carpets
                  are clean and fresh, creating a healthier work environment.
                </p>
                {/* <img className="Client" src={Client1} alt="" /> */}
                <div className="Reting">
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                  <Icon icon={starIcon} color="#cf881d" />
                </div>
                <p className="Clinet-name">Neha Patel</p>
                <p className="Designation">Client</p>
              </div>
            </div>
          </div>
        </Slider>
      </div>

      {/* Testimonials Start */}
    </>
  );
}

export default Testimonial;
