import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import AboutUs from "./Components/AboutUs/AboutUs";
import Residential from "./Components/Services/Residential";
import Commercial from "./Components/Services/Commercial";
import Window from "./Components/Services/Window";
import Blog from "./Components/Blogs/Blog";
import Contact from "./Components/ContactUs/Contact";
import Office from "./Components/Services/Office";
import Grout from "./Components/Services/Grout";
import Car from "./Components/Services/Car";
import Upholstery from "./Components/Services/Upholstery";
import Rugs from "./Components/Services/Rugs";
import OfficeCarpet from "./Components/Services/OfficeCarpet"

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/CarpetCleaning" element={<Residential />} />
          <Route path="/VacateCleaning" element={<Commercial />} />
          <Route path="/WindowCleaning" element={<Window />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Office" element={<Office />} />
          <Route path="/Grout" element={<Grout />} />
          <Route path="/Pressurecleaning" element={<Car />} />
          <Route path="/Upholstery" element={<Upholstery />} />
          <Route path="/Rugs" element={<Rugs />} />
          <Route path="/OfficeCarpet" element={<OfficeCarpet />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
