import React, { useState, useEffect } from "react";
import Modal from "react-modal"; // Import the react-modal library
import "./Blog.css";

Modal.setAppElement("#root"); // Set the root element as the app element

const customStyles = {
  overlay: {
    zIndex: 1000, // Set the z-index for the overlay (modal backdrop)
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1001, // Set the z-index for the modal content
    width: "70%", // Default width for larger screens
    height: "70%", // Default height for larger screens
    padding: "0",  // Default padding
  },
};

// Adjust styles for screens with a width less than 700px using media query
if (window.innerWidth < 700) {
  customStyles.content.width = "92%";
  customStyles.content.height = "40%";
  customStyles.content.padding = "10px";
}

const Blog = () => {
  const [blogData, setBlogData] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState(null);

  useEffect(() => {
    // Fetch data from the API
    fetch("https://gleamingfloors.com.au/admin/db/api/blogs.php")
      .then((response) => response.json())
      .then((data) => {
        // Set the fetched data to the state variable, and sort it in reverse order by the 'id' property
        setBlogData(data.sort((a, b) => b.id - a.id));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const openMedia = (media) => {
    setSelectedMedia(media);
  };

  const closeMedia = () => {
    setSelectedMedia(null);
  };


  return (
    <>

<div className="Dammy"></div>
      <div className="Main-Blogs">
        {blogData.map((post, index) => {
          // Start from the end of the array and iterate backwards
          return (
            <div className="Blog" key={post.id}>
              {post.media_type === "video/mp4" ? (
                <video
                  className="Blog-img"
                  loop
                  muted
                  onClick={() => openMedia(post.media_file)}
                >
                  <source
                    src={
                      "https://gleamingfloors.com.au/admin/pages/" +
                      post.media_file
                    }
                    type="video/mp4"
                  />
                </video>
              ) : (
                <img
                  className="Blog-img"
                  src={
                    "https://gleamingfloors.com.au/admin/pages/" +
                    post.media_file
                  }
                  alt="Blog Image"
                  onClick={() => openMedia(post.media_file)}
                />
              )}
              <p className="Blog-date">{post.date}</p>
              <h2 className="Blog-head">{post.title}</h2>
              <p className="Blog-Content">{post.sub_title}</p>
            </div>
          );
        })}
      </div>
      <Modal
        isOpen={!!selectedMedia}
        onRequestClose={closeMedia}
        contentLabel="Media Modal"
        style={customStyles}
      >
        {selectedMedia?.endsWith(".mp4") ? (
          <video
            className="modal-video"
            controls
            autoPlay
            width="100%" // Ensure video fits within modal
            height="100%" // Ensure video fits within modal
          >
            <source
              src={"https://gleamingfloors.com.au/admin/pages/" + selectedMedia}
              type="video/mp4"
            />
          </video>
        ) : (
          <img
            className="modal-image"
            src={"https://gleamingfloors.com.au/admin/pages/" + selectedMedia}
            alt="Media"
            width="100%" // Ensure image fits within modal
            height="100%" // Ensure image fits within modal
          />
        )}
      </Modal>
    </>
  );
}

export default Blog;
